
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue'
import { MyAthleteMixin, StringsMixin, BAIconsMixin, PaginatedTableMixin, AthleteRoutingMixin } from '@/mixins';
import { AthleteReportModelEx, CoachProfileModel, TeamModel, PlayerOnTeam } from "@/models";
import { athleteReportApi } from "@/api/AthleteReportApi";
import { DataTableHeader } from 'vuetify';
import { notificationStore } from "@/store";
import { isValidUUID } from "@/pipes";

@Component({
	components:{
		Page,
	},
})
export default class AthleteReportsSummary extends Mixins(StringsMixin, MyAthleteMixin, BAIconsMixin, PaginatedTableMixin, AthleteRoutingMixin) {
    athleteReports: Array<AthleteReportModelEx> = [];
    isReportsLoading: boolean = false;
    isReportsLoaded: boolean = false;
    coaches: Array<CoachProfileModel> = [];
    teams: Array<TeamModel> = [];
    teamAthletes: Array<PlayerOnTeam> = [];

    mounted() {
        this.loadReports();
    }

    async loadReports() {
        this.isReportsLoading = true;
        this.isReportsLoaded = false;

        try {
            const reports = await athleteReportApi.findByAthleteId(this.TheAthleteId);
            this.athleteReports = await Promise.all(reports.map(async (report) => {
                const reportEx = new AthleteReportModelEx().load(report);
                if( this.IsNotEmpty(reportEx) ) {
                    await reportEx.loadCoach(this.coaches);
                    const coach = this.coaches.find(c => c.id === reportEx.coach?.id );
                    if( this.IsEmpty(coach) ) this.coaches.push(reportEx.coach);

                    await reportEx.loadTeam(this.teams);
                    if( isValidUUID(reportEx?.teamId) ) {
                        const team = this.teams.find(t => t.id === reportEx.team?.id );
                        if( this.IsEmpty(team) ) this.teams.push(reportEx.team);
                    }
                }
                return reportEx;
            }));
        } catch(e) {
            notificationStore.pushSnackbarError({message: e});
        }

        this.isReportsLoaded = true;
        this.isReportsLoading = false;
    }
    get TableHeaders(): Array<DataTableHeader<any>> {
        return [
            { text: 'Date', value: 'date', sortable: true, },
            { text: 'Coach', value: 'coachId', sortable: false, },
            { text: 'Team', value: 'teamId', sortable: true, },
            { text: 'Rating', value: 'rating', sortable: false, },
            { text: '', value: 'actions', sortable: false },
        ] 
    }
    get IsTableLoading() {
        return this.isReportsLoading;
    }
    get TotalItems() {
        return this.athleteReports.length;
    }
    get LatestReport(): AthleteReportModelEx {
        if( !this.HasItems ) return undefined;
        const sorted = this.athleteReports.sort((a, b) => b.date.getMilliseconds() - a.date.getMilliseconds());
        return sorted[0];
    }

	get LatestAthleteReport(): AthleteReportModelEx {
		if( this.IsEmpty(this.athleteReports) ) return undefined;
		return this.athleteReports.slice(-1)[0];
	}


    async onOpenAthleteReport(athleteReport: AthleteReportModelEx) {
        this.gotoAthleteReport(athleteReport.id);
    }
    async onDeleteAthleteReport(athleteReport: AthleteReportModelEx) {
        if( !confirm(`Delete report for ${athleteReport.LastName}? This action cannot be undone`) ) return;
        await athleteReportApi.delete(athleteReport);
        this.loadReports();
    }
}

